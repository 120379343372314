/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    home: {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function(
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

var mobileMenuOpen = false;

//liams additions
jQuery(document).ready(function($) {
  //you can now use $ as your jQuery object.
  var body = $("body");
  var barSearch = $(".bar-search");

  //keep search form visible on search results page
  if ($(body).hasClass("search-results")) {
    $(function() {
      var input = $("#headerSearch");
      var len = input.val().length;
      input[0].focus();
      input[0].setSelectionRange(len, len);
    });
    $(barSearch).addClass("visible");
    $(barSearch).removeClass("bar-search--animated");
    $(".search-icon--container").addClass("open");
  }

  if ($(body).hasClass("search-no-results")) {
  }

  //hamburger animates to x
  jQuery("#nav-hamburger").click(function() {});

  function toggleHamburger() {
    $("#nav-hamburger").toggleClass("open");
  }

  function openCloseMobileMenu() {
    if (mobileMenuOpen === false) {
      mobileMenuOpen = true;
    } else {
      mobileMenuOpen = false;
    }

    $(".overlay").toggleClass("open");
    $(".nav-wrapper").toggleClass("invert-menu");
    $(".nav-container")
      .delay(3000)
      .removeClass("shift-up");
    toggleHamburger();
  }

  //hamburger showing mobile nav when tapped
  $("#nav-hamburger, .overlay-close").click(function(e) {
    openCloseMobileMenu();
  });

  $("html").on("touchstart", function(e) {
    if (mobileMenuOpen === true) {
      openCloseMobileMenu();
    }
  });

  $(".overlay-slidedown, #nav-hamburger").on("touchstart", function(e) {
    e.stopPropagation();
  });

  //toggle search bar when search icon is clicked
  $(".search-icon--container").click(function(e) {
    $(this).toggleClass("open");
    $(barSearch).addClass("bar-search--animated");
    $(barSearch).toggleClass("visible");
    $("#headerSearch").focus();
  });

  //removes empty paragraphs in articles
  $("p").each(function() {
    if ($.trim($(this).text()) === "" && $(this).children().length === 0) {
      $(this).hide();
    }
  });

  $("[tabindex]").each(function() {
    console.log($(this).attr("tabindex"));
  });
});

jQuery(function ($) {
  $('.expandable-menu a').click(function () {
    $(this).toggleClass('expandable-menu--expanded');
    console.log('hey there smithy');
    $(this).siblings('.sub-menu').toggleClass('sub-menu--expanded');
  });
});

// jQuery(function ($) {
//   $('nav.menu>ul>li').hover(function () {
//     $(this).children('a').addClass('hover_triangle');
//   }, function () {
//     $(this).children('a').removeClass('hover_triangle');
//   });
// });
